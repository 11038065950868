import { Cloudinary } from 'cloudinary-core'

export var cloudinaryMixin = {
	data() {
		return {
			cl: undefined,
			clientWidth: undefined,
			cloudName:
				process.env.NODE_ENV === 'production'
					? process.env.CLOUDINARY_CLOUD_NAME
					: 'pactcoffee-dev',
			noImg: 'https://res.cloudinary.com/pactcoffee/image/upload/v1597850071/website-d2c/assets/kopi/not-found.png'
		}
	},
	mounted() {
		this.cl = Cloudinary.new()
		this.cl.config('cloud_name', this.cloudName)
		// To remove the background hack
		this.setWidth()
		window.addEventListener('resize', this.setWidth)
	},
	methods: {
		setWidth() {
			// To remove the background hack
			this.clientWidth = window.innerWidth
		},
		getImageByIndexCrop(images, index, width, height) {
			if (this.cl && images && images.length !== 0)
				return this.cl.url(images[index], {
					width: width,
					height: height,
					crop: 'crop',
					gravity: 'center',
					quality: 'auto',
					fetchFormat: 'auto',
					type: 'fetch',
				})
			else return this.noImg
		},
		getImageCrop(image, width, height) {
			if (this.cl && image && Object.keys(image).length !== 0)
				return this.cl.url(image, {
					width: width,
					height: height,
					crop: 'crop',
					gravity: 'center',
					quality: 'auto',
					fetchFormat: 'auto',
					type: 'fetch',
				})
			else return this.noImg
		},
		getEquipmentImages(product) {
			if (this.clientWidth < 350) // Mobile S
				return this.getImageByIndexCrop(product.images, 0, 1100, 1000)
			else if (this.clientWidth > 349 && this.clientWidth < 400) // Mobile M
				return this.getImageByIndexCrop(product.images, 0, 1250, 1000)
			else if (this.clientWidth > 399 && this.clientWidth < 576) // Mobile L
				return this.getImageByIndexCrop(product.images, 0, 1250, 1000)
			else if (this.clientWidth > 575 && this.clientWidth < 900) // Tablets S
				return this.getImageByIndexCrop(product.images, 0, 1150, 1105)
			else if (this.clientWidth > 899 && this.clientWidth < 992) // Tablets M
				return this.getImageByIndexCrop(product.images, 0, 1150, 1100)
			else if (this.clientWidth > 991 && this.clientWidth < 1200) // Laptops S
				return this.getImageByIndexCrop(product.images, 0, 1280, 1300)
			else return this.getImageByIndexCrop(product.images, 0, 1200, 1150)
		},
    getCoffeeImages(product) {
      // PLPs images for coffees
			if (this.clientWidth < 568) // Mobile
				return this.getImageByIndexCrop(product.images, 0, 1200, 1000)
			else if (this.clientWidth >=568 && this.clientWidth < 768) // Tablets S
				return this.getImageByIndexCrop(product.images, 0, 1200, 1200)
			else if (this.clientWidth >= 768 && this.clientWidth < 1500) // Tablets and Laptops S/M
				return this.getImageByIndexCrop(product.images, 0, 1200, 1100)
			else
				return this.getImageByIndexCrop(product.images, 0, 1200, 1200)
		},
		getCoffeeImage(image) {
			if (this.clientWidth < 350) // Mobile S
				return this.getImageCrop(image, 1100, 1000)
			else if (this.clientWidth > 349 && this.clientWidth < 400) // Mobile M
				return this.getImageCrop(image, 1250, 1000)
			else if (this.clientWidth > 399 && this.clientWidth < 576) // Mobile L
				return this.getImageCrop(image, 1250, 1000)
			else return this.getImageCrop(image, 1000, 1150)
		},
		getCoffeeImageNewPDP(image) {
			if (this.clientWidth < 991)
				return this.getImageCrop(image, 1050, 1050)
			else if (this.clientWidth > 992 && this.clientWidth < 1500)
				return this.getImageCrop(image, 1000, 1125)
			else return this.getImageCrop(image, 1050, 1050)
		},
		getImageByIndex(images, index, width, height, crop) {
			if (this.cl !== undefined && images !== undefined && images.length !== 0)
				return this.cl.url(images[index], {
					width: width,
					height: height,
					crop: crop,
					quality: 'auto',
					fetchFormat: 'auto',
					type: 'fetch',
				})
			else return this.noImg
		},
		getImg2ImageViewer(url) {
			if (this.clientWidth >= 1200 && this.clientWidth < 1500)
				return this.getImageCrop(url, 1280, 1500)
			else if (this.clientWidth >= 1500)
				return this.getImageCrop(url, 1280, 1250)
      else if (this.clientWidth >= 768 && this.clientWidth < 992)
        return this.getImageCrop(url, 1200, 1050)
      else if (this.clientWidth >= 992 && this.clientWidth < 1200)
        return this.getImageCrop(url, 1000, 1500)
			else if (this.clientWidth >= 476 && this.clientWidth < 568)
				return this.getImageCrop(url, 1200, 1200)
      else if (this.clientWidth >= 400 && this.clientWidth < 476)
				return this.getImageCrop(url, 1280, 1200)
      else return this.getImageCrop(url, 1130, 1200)
		},
		getImg2Mobile(url, cropWidth, cropHeight, limitWidth) {
			if (this.cl) {
				var transf = this.cl.transformation().width(cropWidth).height(cropHeight).crop('crop').gravity('center').fetchFormat('auto').quality('auto').chain().crop('limit').width(limitWidth)
				var cloudinaryFetch = 'http://res.cloudinary.com/'+ this.cloudName + '/image/fetch/'			
				return cloudinaryFetch + transf.serialize() + '/' + url
			}
		},
		getOptimizedImage(url, width) {
			if (this.cl)
				return this.cl.url(url, {
					width: width,
					crop: 'limit',
					quality: 'auto',
					fetchFormat: 'auto',
					type: 'fetch',
				})
			else return this.noImg
		},
    getOptimizedImage2(url) {
			if (this.cl)
				return this.cl.url(url, {
					quality: 'auto',
					fetchFormat: 'auto',
					type: 'fetch',
				})
			else return this.noImg
		}
	},
}
