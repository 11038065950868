<template>
	<div v-if="products.length > 0" id="why-not-try" class="pt-4 pb-5">
		<h6 class="t-secondary-dark m-xs baddons-maintitle">{{ title }}</h6>
      <hr class="hr-baddon mb-3">
    <slideshow
      ref="addons-basket-swipper"
      :slides="usedSlides"
      :slidesToShow="1"
      :hasDots="false"
      :wrapAround="false"
      :isDrag="true"
      :hasAnimations="true"
      :leftButton="usedSlideIndex === 0 ? '/assets/icons/gray/chevron_left.svg' : '/assets/icons/primary/chevron_left.svg'"
      :rightButton="usedSlideIndex === usedSlides.length - 1 ? '/assets/icons/gray/chevron_right.svg' : '/assets/icons/primary/chevron_right.svg'"
      :slideChosen="usedSlideIndex"
      :paddingDefault="'px-2'" 
      @updatedSlideshowIndex="chosenProduct"
    >
      <template #content="{ slide }">
        <BasketAddonElement :element="slide" :addcart="addonToBasket"/>
      </template>
    </slideshow>
      <hr class="hr-baddon two">
	</div>
</template>
<script>
import { defineAsyncComponent } from 'vue'
import { cloudinaryMixin } from './mixins/cloudinary'
import BasketAddonElement from '../SharedShop/elements/BasketAddons/BasketAddonElement.vue'
export default {
	name: 'ProductSwipperBasket',
	components: {
    Slideshow: defineAsyncComponent(() => import('./slideshow/slideshow.vue')),
    BasketAddonElement
},
	mixins: [cloudinaryMixin],
	props: {
		products: { type: Array, required: true },
		title: { type: String, required: true, default: ''},
		addonToBasket: { type: Function, required: true },
	},
	data() {
		return {
			chosenOption: undefined,
		}
	},
  mounted(){
    if(this.chosenOption === undefined)
      this.chosenOption = this.usedSlides[0]
  },
	computed: {
    usedSlides() {
			return this.products.map((product,index) => { return {...product, toggle: true, index: index }})
		},
    usedSlideIndex() {
      if(this.chosenOption){
			  var slide = this.usedSlides.find((slide) => slide.index === this.chosenOption.index)
			  if(slide) return slide.index
      } else return 0
		},
	},
  methods:{
    chosenProduct(val){
      this.chosenOption = this.usedSlides[val]
    },
  }
}
</script>
<style lang="scss">
.wrap-limit {
	height: 270px !important;
}
.baddons-maintitle{
  font-family: Apercu;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.hr-baddon{
  border-bottom: 1px solid #E5E5E5;
  &.two{
    margin-top: -20px;
  }
}
</style>
