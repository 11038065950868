/**
 * @memberOf Qualifiers
 * @description This namespace contains all the colors used in the SDK
 * @namespace Color
 * @example
 * // Reference only, do NOT use within your code for tree-shaking reasons
 * // SDK functions that require color accept a string (like 'red') or a hex value, like 'ffffff'
 * import {Color} from '@cloudinary/url-gen/qualifiers/color'
 * console.log(Color.RED);
 */
const Color = {
    SNOW: 'snow',
    SNOW1: 'snow1',
    SNOW2: 'snow2',
    ROSYBROWN1: 'rosybrown1',
    ROSYBROWN2: 'rosybrown2',
    SNOW3: 'snow3',
    LIGHTCORAL: 'lightcoral',
    INDIANRED1: 'indianred1',
    ROSYBROWN3: 'rosybrown3',
    INDIANRED2: 'indianred2',
    ROSYBROWN: 'rosybrown',
    BROWN1: 'brown1',
    FIREBRICK1: 'firebrick1',
    BROWN2: 'brown2',
    INDIANRED: 'indianred',
    INDIANRED3: 'indianred3',
    FIREBRICK2: 'firebrick2',
    SNOW4: 'snow4',
    BROWN3: 'brown3',
    RED: 'red',
    RED1: 'red1',
    ROSYBROWN4: 'rosybrown4',
    FIREBRICK3: 'firebrick3',
    RED2: 'red2',
    FIREBRICK: 'firebrick',
    BROWN: 'brown',
    RED3: 'red3',
    INDIANRED4: 'indianred4',
    BROWN4: 'brown4',
    FIREBRICK4: 'firebrick4',
    DARKRED: 'darkred',
    RED4: 'red4',
    LIGHTPINK1: 'lightpink1',
    LIGHTPINK3: 'lightpink3',
    LIGHTPINK4: 'lightpink4',
    LIGHTPINK2: 'lightpink2',
    LIGHTPINK: 'lightpink',
    PINK: 'pink',
    CRIMSON: 'crimson',
    PINK1: 'pink1',
    PINK2: 'pink2',
    PINK3: 'pink3',
    PINK4: 'pink4',
    PALEVIOLETRED4: 'palevioletred4',
    PALEVIOLETRED: 'palevioletred',
    PALEVIOLETRED2: 'palevioletred2',
    PALEVIOLETRED1: 'palevioletred1',
    PALEVIOLETRED3: 'palevioletred3',
    LAVENDERBLUSH: 'lavenderblush',
    LAVENDERBLUSH1: 'lavenderblush1',
    LAVENDERBLUSH3: 'lavenderblush3',
    LAVENDERBLUSH2: 'lavenderblush2',
    LAVENDERBLUSH4: 'lavenderblush4',
    MAROON: 'maroon',
    HOTPINK3: 'hotpink3',
    VIOLETRED3: 'violetred3',
    VIOLETRED1: 'violetred1',
    VIOLETRED2: 'violetred2',
    VIOLETRED4: 'violetred4',
    HOTPINK2: 'hotpink2',
    HOTPINK1: 'hotpink1',
    HOTPINK4: 'hotpink4',
    HOTPINK: 'hotpink',
    DEEPPINK: 'deeppink',
    DEEPPINK1: 'deeppink1',
    DEEPPINK2: 'deeppink2',
    DEEPPINK3: 'deeppink3',
    DEEPPINK4: 'deeppink4',
    MAROON1: 'maroon1',
    MAROON2: 'maroon2',
    MAROON3: 'maroon3',
    MAROON4: 'maroon4',
    MEDIUMVIOLETRED: 'mediumvioletred',
    VIOLETRED: 'violetred',
    ORCHID2: 'orchid2',
    ORCHID: 'orchid',
    ORCHID1: 'orchid1',
    ORCHID3: 'orchid3',
    ORCHID4: 'orchid4',
    THISTLE1: 'thistle1',
    THISTLE2: 'thistle2',
    PLUM1: 'plum1',
    PLUM2: 'plum2',
    THISTLE: 'thistle',
    THISTLE3: 'thistle3',
    PLUM: 'plum',
    VIOLET: 'violet',
    PLUM3: 'plum3',
    THISTLE4: 'thistle4',
    FUCHSIA: 'fuchsia',
    MAGENTA: 'magenta',
    MAGENTA1: 'magenta1',
    PLUM4: 'plum4',
    MAGENTA2: 'magenta2',
    MAGENTA3: 'magenta3',
    DARKMAGENTA: 'darkmagenta',
    MAGENTA4: 'magenta4',
    PURPLE: 'purple',
    MEDIUMORCHID: 'mediumorchid',
    MEDIUMORCHID1: 'mediumorchid1',
    MEDIUMORCHID2: 'mediumorchid2',
    MEDIUMORCHID3: 'mediumorchid3',
    MEDIUMORCHID4: 'mediumorchid4',
    DARKVIOLET: 'darkviolet',
    DARKORCHID: 'darkorchid',
    DARKORCHID1: 'darkorchid1',
    DARKORCHID3: 'darkorchid3',
    DARKORCHID2: 'darkorchid2',
    DARKORCHID4: 'darkorchid4',
    INDIGO: 'indigo',
    BLUEVIOLET: 'blueviolet',
    PURPLE2: 'purple2',
    PURPLE3: 'purple3',
    PURPLE4: 'purple4',
    PURPLE1: 'purple1',
    MEDIUMPURPLE: 'mediumpurple',
    MEDIUMPURPLE1: 'mediumpurple1',
    MEDIUMPURPLE2: 'mediumpurple2',
    MEDIUMPURPLE3: 'mediumpurple3',
    MEDIUMPURPLE4: 'mediumpurple4',
    DARKSLATEBLUE: 'darkslateblue',
    LIGHTSLATEBLUE: 'lightslateblue',
    MEDIUMSLATEBLUE: 'mediumslateblue',
    SLATEBLUE: 'slateblue',
    SLATEBLUE1: 'slateblue1',
    SLATEBLUE2: 'slateblue2',
    SLATEBLUE3: 'slateblue3',
    SLATEBLUE4: 'slateblue4',
    GHOSTWHITE: 'ghostwhite',
    LAVENDER: 'lavender',
    BLUE: 'blue',
    BLUE1: 'blue1',
    BLUE2: 'blue2',
    BLUE3: 'blue3',
    MEDIUMBLUE: 'mediumblue',
    BLUE4: 'blue4',
    DARKBLUE: 'darkblue',
    MIDNIGHTBLUE: 'midnightblue',
    NAVY: 'navy',
    NAVYBLUE: 'navyblue',
    ROYALBLUE: 'royalblue',
    ROYALBLUE1: 'royalblue1',
    ROYALBLUE2: 'royalblue2',
    ROYALBLUE3: 'royalblue3',
    ROYALBLUE4: 'royalblue4',
    CORNFLOWERBLUE: 'cornflowerblue',
    LIGHTSTEELBLUE: 'lightsteelblue',
    LIGHTSTEELBLUE1: 'lightsteelblue1',
    LIGHTSTEELBLUE2: 'lightsteelblue2',
    LIGHTSTEELBLUE3: 'lightsteelblue3',
    LIGHTSTEELBLUE4: 'lightsteelblue4',
    SLATEGRAY4: 'slategray4',
    SLATEGRAY1: 'slategray1',
    SLATEGRAY2: 'slategray2',
    SLATEGRAY3: 'slategray3',
    LIGHTSLATEGRAY: 'lightslategray',
    LIGHTSLATEGREY: 'lightslategrey',
    SLATEGRAY: 'slategray',
    SLATEGREY: 'slategrey',
    DODGERBLUE: 'dodgerblue',
    DODGERBLUE1: 'dodgerblue1',
    DODGERBLUE2: 'dodgerblue2',
    DODGERBLUE4: 'dodgerblue4',
    DODGERBLUE3: 'dodgerblue3',
    ALICEBLUE: 'aliceblue',
    STEELBLUE4: 'steelblue4',
    STEELBLUE: 'steelblue',
    STEELBLUE1: 'steelblue1',
    STEELBLUE2: 'steelblue2',
    STEELBLUE3: 'steelblue3',
    SKYBLUE4: 'skyblue4',
    SKYBLUE1: 'skyblue1',
    SKYBLUE2: 'skyblue2',
    SKYBLUE3: 'skyblue3',
    LIGHTSKYBLUE: 'lightskyblue',
    LIGHTSKYBLUE4: 'lightskyblue4',
    LIGHTSKYBLUE1: 'lightskyblue1',
    LIGHTSKYBLUE2: 'lightskyblue2',
    LIGHTSKYBLUE3: 'lightskyblue3',
    SKYBLUE: 'skyblue',
    LIGHTBLUE3: 'lightblue3',
    DEEPSKYBLUE: 'deepskyblue',
    DEEPSKYBLUE1: 'deepskyblue1',
    DEEPSKYBLUE2: 'deepskyblue2',
    DEEPSKYBLUE4: 'deepskyblue4',
    DEEPSKYBLUE3: 'deepskyblue3',
    LIGHTBLUE1: 'lightblue1',
    LIGHTBLUE2: 'lightblue2',
    LIGHTBLUE: 'lightblue',
    LIGHTBLUE4: 'lightblue4',
    POWDERBLUE: 'powderblue',
    CADETBLUE1: 'cadetblue1',
    CADETBLUE2: 'cadetblue2',
    CADETBLUE3: 'cadetblue3',
    CADETBLUE4: 'cadetblue4',
    TURQUOISE1: 'turquoise1',
    TURQUOISE2: 'turquoise2',
    TURQUOISE3: 'turquoise3',
    TURQUOISE4: 'turquoise4',
    CADETBLUE: 'cadetblue',
    DARKTURQUOISE: 'darkturquoise',
    AZURE: 'azure',
    AZURE1: 'azure1',
    LIGHTCYAN1: 'lightcyan1',
    LIGHTCYAN: 'lightcyan',
    AZURE2: 'azure2',
    LIGHTCYAN2: 'lightcyan2',
    PALETURQUOISE1: 'paleturquoise1',
    PALETURQUOISE: 'paleturquoise',
    PALETURQUOISE2: 'paleturquoise2',
    DARKSLATEGRAY1: 'darkslategray1',
    AZURE3: 'azure3',
    LIGHTCYAN3: 'lightcyan3',
    DARKSLATEGRAY2: 'darkslategray2',
    PALETURQUOISE3: 'paleturquoise3',
    DARKSLATEGRAY3: 'darkslategray3',
    AZURE4: 'azure4',
    LIGHTCYAN4: 'lightcyan4',
    AQUA: 'aqua',
    CYAN: 'cyan',
    CYAN1: 'cyan1',
    PALETURQUOISE4: 'paleturquoise4',
    CYAN2: 'cyan2',
    DARKSLATEGRAY4: 'darkslategray4',
    CYAN3: 'cyan3',
    CYAN4: 'cyan4',
    DARKCYAN: 'darkcyan',
    TEAL: 'teal',
    DARKSLATEGRAY: 'darkslategray',
    DARKSLATEGREY: 'darkslategrey',
    MEDIUMTURQUOISE: 'mediumturquoise',
    LIGHTSEAGREEN: 'lightseagreen',
    TURQUOISE: 'turquoise',
    AQUAMARINE4: 'aquamarine4',
    AQUAMARINE: 'aquamarine',
    AQUAMARINE1: 'aquamarine1',
    AQUAMARINE2: 'aquamarine2',
    AQUAMARINE3: 'aquamarine3',
    MEDIUMAQUAMARINE: 'mediumaquamarine',
    MEDIUMSPRINGGREEN: 'mediumspringgreen',
    MINTCREAM: 'mintcream',
    SPRINGGREEN: 'springgreen',
    SPRINGGREEN1: 'springgreen1',
    SPRINGGREEN2: 'springgreen2',
    SPRINGGREEN3: 'springgreen3',
    SPRINGGREEN4: 'springgreen4',
    MEDIUMSEAGREEN: 'mediumseagreen',
    SEAGREEN: 'seagreen',
    SEAGREEN3: 'seagreen3',
    SEAGREEN1: 'seagreen1',
    SEAGREEN4: 'seagreen4',
    SEAGREEN2: 'seagreen2',
    MEDIUMFORESTGREEN: 'mediumforestgreen',
    HONEYDEW: 'honeydew',
    HONEYDEW1: 'honeydew1',
    HONEYDEW2: 'honeydew2',
    DARKSEAGREEN1: 'darkseagreen1',
    DARKSEAGREEN2: 'darkseagreen2',
    PALEGREEN1: 'palegreen1',
    PALEGREEN: 'palegreen',
    HONEYDEW3: 'honeydew3',
    LIGHTGREEN: 'lightgreen',
    PALEGREEN2: 'palegreen2',
    DARKSEAGREEN3: 'darkseagreen3',
    DARKSEAGREEN: 'darkseagreen',
    PALEGREEN3: 'palegreen3',
    HONEYDEW4: 'honeydew4',
    GREEN1: 'green1',
    LIME: 'lime',
    LIMEGREEN: 'limegreen',
    DARKSEAGREEN4: 'darkseagreen4',
    GREEN2: 'green2',
    PALEGREEN4: 'palegreen4',
    GREEN3: 'green3',
    FORESTGREEN: 'forestgreen',
    GREEN4: 'green4',
    GREEN: 'green',
    DARKGREEN: 'darkgreen',
    LAWNGREEN: 'lawngreen',
    CHARTREUSE: 'chartreuse',
    CHARTREUSE1: 'chartreuse1',
    CHARTREUSE2: 'chartreuse2',
    CHARTREUSE3: 'chartreuse3',
    CHARTREUSE4: 'chartreuse4',
    GREENYELLOW: 'greenyellow',
    DARKOLIVEGREEN3: 'darkolivegreen3',
    DARKOLIVEGREEN1: 'darkolivegreen1',
    DARKOLIVEGREEN2: 'darkolivegreen2',
    DARKOLIVEGREEN4: 'darkolivegreen4',
    DARKOLIVEGREEN: 'darkolivegreen',
    OLIVEDRAB: 'olivedrab',
    OLIVEDRAB1: 'olivedrab1',
    OLIVEDRAB2: 'olivedrab2',
    OLIVEDRAB3: 'olivedrab3',
    YELLOWGREEN: 'yellowgreen',
    OLIVEDRAB4: 'olivedrab4',
    IVORY: 'ivory',
    IVORY1: 'ivory1',
    LIGHTYELLOW: 'lightyellow',
    LIGHTYELLOW1: 'lightyellow1',
    BEIGE: 'beige',
    IVORY2: 'ivory2',
    LIGHTGOLDENRODYELLOW: 'lightgoldenrodyellow',
    LIGHTYELLOW2: 'lightyellow2',
    IVORY3: 'ivory3',
    LIGHTYELLOW3: 'lightyellow3',
    IVORY4: 'ivory4',
    LIGHTYELLOW4: 'lightyellow4',
    YELLOW: 'yellow',
    YELLOW1: 'yellow1',
    YELLOW2: 'yellow2',
    YELLOW3: 'yellow3',
    YELLOW4: 'yellow4',
    OLIVE: 'olive',
    DARKKHAKI: 'darkkhaki',
    KHAKI2: 'khaki2',
    LEMONCHIFFON4: 'lemonchiffon4',
    KHAKI1: 'khaki1',
    KHAKI3: 'khaki3',
    KHAKI4: 'khaki4',
    PALEGOLDENROD: 'palegoldenrod',
    LEMONCHIFFON: 'lemonchiffon',
    LEMONCHIFFON1: 'lemonchiffon1',
    KHAKI: 'khaki',
    LEMONCHIFFON3: 'lemonchiffon3',
    LEMONCHIFFON2: 'lemonchiffon2',
    MEDIUMGOLDENROD: 'mediumgoldenrod',
    CORNSILK4: 'cornsilk4',
    GOLD: 'gold',
    GOLD1: 'gold1',
    GOLD2: 'gold2',
    GOLD3: 'gold3',
    GOLD4: 'gold4',
    LIGHTGOLDENROD: 'lightgoldenrod',
    LIGHTGOLDENROD4: 'lightgoldenrod4',
    LIGHTGOLDENROD1: 'lightgoldenrod1',
    LIGHTGOLDENROD3: 'lightgoldenrod3',
    LIGHTGOLDENROD2: 'lightgoldenrod2',
    CORNSILK3: 'cornsilk3',
    CORNSILK2: 'cornsilk2',
    CORNSILK: 'cornsilk',
    CORNSILK1: 'cornsilk1',
    GOLDENROD: 'goldenrod',
    GOLDENROD1: 'goldenrod1',
    GOLDENROD2: 'goldenrod2',
    GOLDENROD3: 'goldenrod3',
    GOLDENROD4: 'goldenrod4',
    DARKGOLDENROD: 'darkgoldenrod',
    DARKGOLDENROD1: 'darkgoldenrod1',
    DARKGOLDENROD2: 'darkgoldenrod2',
    DARKGOLDENROD3: 'darkgoldenrod3',
    DARKGOLDENROD4: 'darkgoldenrod4',
    FLORALWHITE: 'floralwhite',
    WHEAT2: 'wheat2',
    OLDLACE: 'oldlace',
    WHEAT: 'wheat',
    WHEAT1: 'wheat1',
    WHEAT3: 'wheat3',
    ORANGE: 'orange',
    ORANGE1: 'orange1',
    ORANGE2: 'orange2',
    ORANGE3: 'orange3',
    ORANGE4: 'orange4',
    WHEAT4: 'wheat4',
    MOCCASIN: 'moccasin',
    PAPAYAWHIP: 'papayawhip',
    NAVAJOWHITE3: 'navajowhite3',
    BLANCHEDALMOND: 'blanchedalmond',
    NAVAJOWHITE: 'navajowhite',
    NAVAJOWHITE1: 'navajowhite1',
    NAVAJOWHITE2: 'navajowhite2',
    NAVAJOWHITE4: 'navajowhite4',
    ANTIQUEWHITE4: 'antiquewhite4',
    ANTIQUEWHITE: 'antiquewhite',
    TAN: 'tan',
    BISQUE4: 'bisque4',
    BURLYWOOD: 'burlywood',
    ANTIQUEWHITE2: 'antiquewhite2',
    BURLYWOOD1: 'burlywood1',
    BURLYWOOD3: 'burlywood3',
    BURLYWOOD2: 'burlywood2',
    ANTIQUEWHITE1: 'antiquewhite1',
    BURLYWOOD4: 'burlywood4',
    ANTIQUEWHITE3: 'antiquewhite3',
    DARKORANGE: 'darkorange',
    BISQUE2: 'bisque2',
    BISQUE: 'bisque',
    BISQUE1: 'bisque1',
    BISQUE3: 'bisque3',
    DARKORANGE1: 'darkorange1',
    LINEN: 'linen',
    DARKORANGE2: 'darkorange2',
    DARKORANGE3: 'darkorange3',
    DARKORANGE4: 'darkorange4',
    PERU: 'peru',
    TAN1: 'tan1',
    TAN2: 'tan2',
    TAN3: 'tan3',
    TAN4: 'tan4',
    PEACHPUFF: 'peachpuff',
    PEACHPUFF1: 'peachpuff1',
    PEACHPUFF4: 'peachpuff4',
    PEACHPUFF2: 'peachpuff2',
    PEACHPUFF3: 'peachpuff3',
    SANDYBROWN: 'sandybrown',
    SEASHELL4: 'seashell4',
    SEASHELL2: 'seashell2',
    SEASHELL3: 'seashell3',
    CHOCOLATE: 'chocolate',
    CHOCOLATE1: 'chocolate1',
    CHOCOLATE2: 'chocolate2',
    CHOCOLATE3: 'chocolate3',
    CHOCOLATE4: 'chocolate4',
    SADDLEBROWN: 'saddlebrown',
    SEASHELL: 'seashell',
    SEASHELL1: 'seashell1',
    SIENNA4: 'sienna4',
    SIENNA: 'sienna',
    SIENNA1: 'sienna1',
    SIENNA2: 'sienna2',
    SIENNA3: 'sienna3',
    LIGHTSALMON3: 'lightsalmon3',
    LIGHTSALMON: 'lightsalmon',
    LIGHTSALMON1: 'lightsalmon1',
    LIGHTSALMON4: 'lightsalmon4',
    LIGHTSALMON2: 'lightsalmon2',
    CORAL: 'coral',
    ORANGERED: 'orangered',
    ORANGERED1: 'orangered1',
    ORANGERED2: 'orangered2',
    ORANGERED3: 'orangered3',
    ORANGERED4: 'orangered4',
    DARKSALMON: 'darksalmon',
    SALMON1: 'salmon1',
    SALMON2: 'salmon2',
    SALMON3: 'salmon3',
    SALMON4: 'salmon4',
    CORAL1: 'coral1',
    CORAL2: 'coral2',
    CORAL3: 'coral3',
    CORAL4: 'coral4',
    TOMATO4: 'tomato4',
    TOMATO: 'tomato',
    TOMATO1: 'tomato1',
    TOMATO2: 'tomato2',
    TOMATO3: 'tomato3',
    MISTYROSE4: 'mistyrose4',
    MISTYROSE2: 'mistyrose2',
    MISTYROSE: 'mistyrose',
    MISTYROSE1: 'mistyrose1',
    SALMON: 'salmon',
    MISTYROSE3: 'mistyrose3',
    WHITE: 'white',
    GRAY100: 'gray100',
    GREY100: 'grey100',
    GRAY99: 'gray99',
    GREY99: 'grey99',
    GRAY98: 'gray98',
    GREY98: 'grey98',
    GRAY97: 'gray97',
    GREY97: 'grey97',
    GRAY96: 'gray96',
    GREY96: 'grey96',
    WHITESMOKE: 'whitesmoke',
    GRAY95: 'gray95',
    GREY95: 'grey95',
    GRAY94: 'gray94',
    GREY94: 'grey94',
    GRAY93: 'gray93',
    GREY93: 'grey93',
    GRAY92: 'gray92',
    GREY92: 'grey92',
    GRAY91: 'gray91',
    GREY91: 'grey91',
    GRAY90: 'gray90',
    GREY90: 'grey90',
    GRAY89: 'gray89',
    GREY89: 'grey89',
    GRAY88: 'gray88',
    GREY88: 'grey88',
    GRAY87: 'gray87',
    GREY87: 'grey87',
    GAINSBORO: 'gainsboro',
    GRAY86: 'gray86',
    GREY86: 'grey86',
    GRAY85: 'gray85',
    GREY85: 'grey85',
    GRAY84: 'gray84',
    GREY84: 'grey84',
    GRAY83: 'gray83',
    GREY83: 'grey83',
    LIGHTGRAY: 'lightgray',
    LIGHTGREY: 'lightgrey',
    GRAY82: 'gray82',
    GREY82: 'grey82',
    GRAY81: 'gray81',
    GREY81: 'grey81',
    GRAY80: 'gray80',
    GREY80: 'grey80',
    GRAY79: 'gray79',
    GREY79: 'grey79',
    GRAY78: 'gray78',
    GREY78: 'grey78',
    GRAY77: 'gray77',
    GREY77: 'grey77',
    GRAY76: 'gray76',
    GREY76: 'grey76',
    SILVER: 'silver',
    GRAY75: 'gray75',
    GREY75: 'grey75',
    GRAY74: 'gray74',
    GREY74: 'grey74',
    GRAY73: 'gray73',
    GREY73: 'grey73',
    GRAY72: 'gray72',
    GREY72: 'grey72',
    GRAY71: 'gray71',
    GREY71: 'grey71',
    GRAY70: 'gray70',
    GREY70: 'grey70',
    GRAY69: 'gray69',
    GREY69: 'grey69',
    GRAY68: 'gray68',
    GREY68: 'grey68',
    GRAY67: 'gray67',
    GREY67: 'grey67',
    DARKGRAY: 'darkgray',
    DARKGREY: 'darkgrey',
    GRAY66: 'gray66',
    GREY66: 'grey66',
    GRAY65: 'gray65',
    GREY65: 'grey65',
    GRAY64: 'gray64',
    GREY64: 'grey64',
    GRAY63: 'gray63',
    GREY63: 'grey63',
    GRAY62: 'gray62',
    GREY62: 'grey62',
    GRAY61: 'gray61',
    GREY61: 'grey61',
    GRAY60: 'gray60',
    GREY60: 'grey60',
    GRAY59: 'gray59',
    GREY59: 'grey59',
    GRAY58: 'gray58',
    GREY58: 'grey58',
    GRAY57: 'gray57',
    GREY57: 'grey57',
    GRAY56: 'gray56',
    GREY56: 'grey56',
    GRAY55: 'gray55',
    GREY55: 'grey55',
    GRAY54: 'gray54',
    GREY54: 'grey54',
    GRAY53: 'gray53',
    GREY53: 'grey53',
    GRAY52: 'gray52',
    GREY52: 'grey52',
    GRAY51: 'gray51',
    GREY51: 'grey51',
    FRACTAL: 'fractal',
    GRAY50: 'gray50',
    GREY50: 'grey50',
    GRAY: 'gray',
    GREY: 'grey',
    GRAY49: 'gray49',
    GREY49: 'grey49',
    GRAY48: 'gray48',
    GREY48: 'grey48',
    GRAY47: 'gray47',
    GREY47: 'grey47',
    GRAY46: 'gray46',
    GREY46: 'grey46',
    GRAY45: 'gray45',
    GREY45: 'grey45',
    GRAY44: 'gray44',
    GREY44: 'grey44',
    GRAY43: 'gray43',
    GREY43: 'grey43',
    GRAY42: 'gray42',
    GREY42: 'grey42',
    DIMGRAY: 'dimgray',
    DIMGREY: 'dimgrey',
    GRAY41: 'gray41',
    GREY41: 'grey41',
    GRAY40: 'gray40',
    GREY40: 'grey40',
    GRAY39: 'gray39',
    GREY39: 'grey39',
    GRAY38: 'gray38',
    GREY38: 'grey38',
    GRAY37: 'gray37',
    GREY37: 'grey37',
    GRAY36: 'gray36',
    GREY36: 'grey36',
    GRAY35: 'gray35',
    GREY35: 'grey35',
    GRAY34: 'gray34',
    GREY34: 'grey34',
    GRAY33: 'gray33',
    GREY33: 'grey33',
    GRAY32: 'gray32',
    GREY32: 'grey32',
    GRAY31: 'gray31',
    GREY31: 'grey31',
    GRAY30: 'gray30',
    GREY30: 'grey30',
    GRAY29: 'gray29',
    GREY29: 'grey29',
    GRAY28: 'gray28',
    GREY28: 'grey28',
    GRAY27: 'gray27',
    GREY27: 'grey27',
    GRAY26: 'gray26',
    GREY26: 'grey26',
    GRAY25: 'gray25',
    GREY25: 'grey25',
    GRAY24: 'gray24',
    GREY24: 'grey24',
    GRAY23: 'gray23',
    GREY23: 'grey23',
    GRAY22: 'gray22',
    GREY22: 'grey22',
    GRAY21: 'gray21',
    GREY21: 'grey21',
    GRAY20: 'gray20',
    GREY20: 'grey20',
    GRAY19: 'gray19',
    GREY19: 'grey19',
    GRAY18: 'gray18',
    GREY18: 'grey18',
    GRAY17: 'gray17',
    GREY17: 'grey17',
    GRAY16: 'gray16',
    GREY16: 'grey16',
    GRAY15: 'gray15',
    GREY15: 'grey15',
    GRAY14: 'gray14',
    GREY14: 'grey14',
    GRAY13: 'gray13',
    GREY13: 'grey13',
    GRAY12: 'gray12',
    GREY12: 'grey12',
    GRAY11: 'gray11',
    GREY11: 'grey11',
    GRAY10: 'gray10',
    GREY10: 'grey10',
    GRAY9: 'gray9',
    GREY9: 'grey9',
    GRAY8: 'gray8',
    GREY8: 'grey8',
    GRAY7: 'gray7',
    GREY7: 'grey7',
    GRAY6: 'gray6',
    GREY6: 'grey6',
    GRAY5: 'gray5',
    GREY5: 'grey5',
    GRAY4: 'gray4',
    GREY4: 'grey4',
    GRAY3: 'gray3',
    GREY3: 'grey3',
    GRAY2: 'gray2',
    GREY2: 'grey2',
    GRAY1: 'gray1',
    GREY1: 'grey1',
    BLACK: 'black',
    GRAY0: 'gray0',
    GREY0: 'grey0',
    OPAQUE: 'opaque',
    NONE: 'none',
    TRANSPARENT: 'transparent'
};
export { Color };
