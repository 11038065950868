import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useCloudinaryHelpers } from '../../utils/composables/cloudinaryHelpers'

// by convention, composable function names start with 'use'
export function useProductInfoHelpers(product, flag_visibility = true) {
	// state encapsulated and managed by the composable
	const store = useStore()

  const { optimizeImage } = useCloudinaryHelpers()

	// Flags
  const product_origin_flag = computed(() => {
		return product?.coffee?.origin_flag === null
			? 'https://res.cloudinary.com/pactcoffee/image/upload/v1651654229/website-d2c/assets/uploadedCMS/2022/Navigation/navbar-icons/earth.svg'
			: product?.coffee?.origin_flag
	})

  // plans
	const available_plans = computed(() => {
		return Object.is(product.coffee, undefined)
			? []
			: product.coffee.coffee_plan_ids.map((id) => {
					return store.getters['shop/getCoffeePlanById'](id)
			  })
	})

  function sort_by_amount(a, b){
    var amount_a = a.amount_value ? a.amount_value : undefined
    var amount_b = b.amount_value ? b.amount_value : undefined

    if(amount_a === undefined && amount_b === undefined ) return 0
    if (amount_a > amount_b) {
			return 1
		} else if (amount_a < amount_b) {
			return -1
		}
  }

	const available_variants = computed(() => {
    if(flag_visibility)
      return product.variants.filter((variant) => variant.available && variant.visible).sort(sort_by_amount)
    else
      return product.variants.filter((variant) => variant.available).sort(sort_by_amount)
	})

  function get_available_variants(prod, flag_visibility = true) {
    if(flag_visibility)
      return prod.variants.filter((variant) => variant.available && variant.visible).sort(sort_by_amount)
    else
      return prod.variants.filter((variant) => variant.available).sort(sort_by_amount)
	}

	const has_multiple_variants = computed(() => {
		return available_variants.value.length > 1
	})

  function get_has_multiple_variants(prod, flag_visibility = true) {
    var available_variants = get_available_variants(prod, flag_visibility)
    return available_variants.length > 1
	}

	const product_lowest_price_variant = computed(() => {
		return available_variants.value.reduce((a, b) => (variant_used_price(a) < variant_used_price(b) ? a : b),[])
	})

  function get_product_lowest_price_variant(prod, flag_visibility = true) {
    var available_variants = get_available_variants(prod, flag_visibility)
    return available_variants.reduce((a, b) => (variant_used_price(a) < variant_used_price(b) ? a : b),[])
	}

	const product_highest_price_variant = computed(() => {
		return available_variants.value.reduce((a, b) => (variant_used_price(a) > variant_used_price(b) ? a : b),[])
	})

  function get_product_highest_price_variant(prod, flag_visibility = true) {
    var available_variants = get_available_variants(prod, flag_visibility)
    return available_variants.reduce((a, b) => (variant_used_price(a) > variant_used_price(b) ? a : b),[])
	}

	const product_lowest_price = computed(() => {
		return product_lowest_price_variant.value.on_offer
    ? product_lowest_price_variant.value.offer.offer_price
    : store_discount.value === 'voucher' && product_lowest_price_variant.value.on_voucher
    ? product_lowest_price_variant.value.voucher.price
    : product_lowest_price_variant.value.price
	})

  // Improve to include the voucher
  function get_product_lowest_price(prod, flag_visibility = true) {
    var product_lowest_price_variant = get_product_lowest_price_variant(prod, flag_visibility)
    return product_lowest_price_variant.on_offer
    ? product_lowest_price_variant.offer.offer_price
    : get_store_discount(prod) === 'voucher' && product_lowest_price_variant.on_voucher
    ? product_lowest_price_variant.voucher.price
    : product_lowest_price_variant.price
	}

	const product_highest_price = computed(() => {
		return product_highest_price_variant.value.on_offer
			? product_highest_price_variant.value.offer.offer_price
      : store_discount.value === 'voucher' && product_highest_price_variant.value.on_voucher
      ? product_highest_price_variant.value.voucher.price
			: product_highest_price_variant.value.price
	})

  // Improve to include the voucher
  function get_product_highest_price(prod, flag_visibility = true) {
    var product_highest_price_variant = get_product_highest_price_variant(prod, flag_visibility)
    return product_highest_price_variant.on_offer
    ? product_highest_price_variant.offer.offer_price
    : get_store_discount(prod) === 'voucher' && product_highest_price_variant.on_voucher
    ? product_highest_price_variant.voucher.price
    : product_highest_price_variant.price
	}

	const product_lowest_price_undiscounted = computed(() => {
		return product_lowest_price_variant.value.price
	})

  function get_product_lowest_price_undiscounted(prod, flag_visibility = true) {
    var product_lowest_price_variant = get_product_lowest_price_variant(prod, flag_visibility)
    return product_lowest_price_variant.price
	}

	// promo
	const product_in_promo = computed(() => {
		return available_variants.value.filter((variant) => variant.on_offer).length === available_variants.value.length && available_variants.value.length > 0
	})

  function get_product_in_promo (prod, flag_visibility = true) {
    var available_variants = get_available_variants(prod, flag_visibility)
		return available_variants.filter((variant) => variant.on_offer).length === available_variants.length && available_variants.length > 0
	}

  function get_variant_in_promo (variant) {
		return variant.on_offer
	}

	const has_variant_on_offer = computed(() => {
		return available_variants.value.filter((variant) => variant.on_offer).length > 0
	})

  function get_has_variant_on_offer (prod, flag_visibility = true) {
    var available_variants = get_available_variants(prod, flag_visibility)
		return available_variants.filter((variant) => variant.on_offer).length > 0
	}

	const lowest_on_offer_variant = computed(() => {
		return has_variant_on_offer.value
			? available_variants.value.filter((variant) => variant.on_offer).reduce((a, b) => (variant_used_price(a) < variant_used_price(b) ? a : b),[])
			: undefined
	})

  function get_lowest_on_offer_variant (prod, flag_visibility = true) {
    var available_variants = get_available_variants(prod, flag_visibility)//.filter((variant) => variant.stock > 0 || variant.stock === null )
		return get_has_variant_on_offer(prod, flag_visibility)
			? available_variants.filter((variant) => variant.on_offer).reduce((a, b) => (variant_used_price(a) < variant_used_price(b) ? a : b),[])
			: undefined
	}

  //plp store discount type
  function get_store_discount(prod){
    return prod.store_discount
  }
  // product on voucher function
	function get_product_on_voucher (prod, flag_visibility = true) {
    var available_variants = get_available_variants(prod, flag_visibility)
		return available_variants.filter((variant) => variant.on_voucher).length === available_variants.length && available_variants.length > 0
	}

  function get_variant_on_voucher (variant) {
		return variant.on_voucher
	}

	function get_has_variant_on_voucher (prod, flag_visibility = true) {
    var available_variants = get_available_variants(prod, flag_visibility)
		return available_variants.filter((variant) => variant.on_voucher).length > 0
	}

	function get_lowest_on_voucher_variant (prod, flag_visibility = true) {
    var available_variants = get_available_variants(prod, flag_visibility)//.filter((variant) => variant.stock > 0 || variant.stock === null )
		return get_has_variant_on_voucher(prod, flag_visibility)
			? available_variants.filter((variant) => variant.on_voucher).reduce((a, b) => (variant_used_price(a) < variant_used_price(b) ? a : b),[])
			: undefined
	}

	// Product type

	const product_is_flexi_pack = computed(() => {
		return product.product_type === 'flexi_pack'
	})

  function get_product_is_flexi_pack (prod) {
		return prod.product_type === 'flexi_pack'
	}

	const product_is_cold_brew = computed(() => {
		return product.category === 'Cold Brew'
	})

  function get_product_is_cold_brew (prod) {
		return prod.category === 'Cold Brew'
	}

	const product_is_equipment = computed(() => {
		return product.product_type !== 'bag' && product.product_type !== 'pod'
	})

  function get_product_is_equipment (prod) {
		return prod.product_type !== 'bag' && prod.product_type !== 'pod'
	}

	const product_is_pod = computed(() => {
		return product.product_type === 'pod'
	})

  function get_product_is_pod (prod) {
		return prod.product_type === 'pod'
	}

	const product_is_bag_tin = computed(() => {
		return product.product_type === 'bag' && product.tin
	})

  function get_product_is_bag_tin (prod) {
		return prod.product_type === 'bag' && prod.tin
	}

	const show_grind = computed(() => {
		return product.product_type === 'bag'
	})

  function get_show_grind(prod) {
		return prod.product_type === 'bag'
	}

	const subscribeandsave_text = computed(() => {
    const final_text = 'Available for '
    var count = 0;
      available_variants.value.map(x => {
        if(productVariantHasPlan(x))
        if( count > 1)
          final_text = final_text + ' and ' + x.amount_display
        else
          final_text = final_text + x.amount_display
        count++
      })
    return final_text + ' bags'
	})

  function get_subscribeandsave_text(prod, flag_visibility = true){
    var available_variants = get_available_variants(prod, flag_visibility)
    const final_text = 'Available for '
    var count = 0;
      available_variants.map(x => {
        if(productVariantHasPlan(x))
        if( count > 1)
          final_text = final_text + ' and ' + x.amount_display
        else
          final_text = final_text + x.amount_display
        count++
      })
      return final_text + ' bags'
  }

  // Product label artwork

  const has_label_artwork = computed(() => {
    return (
      !product_is_equipment.value &&
      product.label_artwork_image &&
      product.label_artwork_image !== null
    )
  })

  const label_artwork_img = computed(() => {
    return `background-image: url(${optimizeImage(product.label_artwork_image)})`
  })

  const card_bg_img = computed(() => {
    return has_label_artwork.value ? label_artwork_img.value : 'background-color: #f5f5f5'
  })

	// Plans

	const coffee_plans = computed(() => {
		if (product.coffee === undefined || product.coffee.coffee_plan_ids.length === 0) return undefined
		else return available_plans.value
	})

	const product_has_one_plan = computed(() => {
		const check_plans = available_plans.value.map((coffee_plan) => {
			return !(
				(coffee_plan && // 👈 null and undefined check
					Object.keys(coffee_plan).length === 0 &&
					Object.getPrototypeOf(coffee_plan) === Object.prototype) ||
				coffee_plan === undefined
			)
		})
		return check_plans.reduce((a, b) => a || b, false)
	})

	// Auxiliary Methods
	function variant_used_price(variant) {
    // offer > voucher > price
    var price_to_use = 0
    if(variant.on_offer)
      price_to_use = variant.offer.offer_price
    else if(get_store_discount(variant) === 'voucher' && variant.on_voucher)
      price_to_use = variant.voucher.price
    else price_to_use = variant.price
		return  parseFloat(price_to_use)
	}

	function productVariantHasPlan(variant) {
		if (variant.amount_type !== 'grams' && variant.amount_type !== 'pods') return false
		else {
			const chosen_plan = available_plans.value.find((plan) => plan?.amount === variant.amount_value)
			if (chosen_plan) return true
		}
    return false
	}

	function getPlanFromVariant(variant) {
		return available_plans.value.find((plan) => plan?.amount === variant.amount_value)
	}

	function getVariantFromVariantSku(variant_sku) {
		return available_variants.value.find((variant) => variant.sku === variant_sku)
	}

	function getVariantDiscountText(variant) {
    if(!variant.on_offer) return ''
    else if(variant.offer.offer_type === 'PERCENTAGE')
      return parseInt(variant.offer.offer_value) +'% OFF'
		return '£'+ parseFloat(variant.offer.offer_price) + ' OFF'
	}

  // out of stock
  const all_variants_out_of_stock = computed(() => {
    return available_variants.value.filter((variant) => variant.stock !== null && variant.stock === 0).length === available_variants.value.length && available_variants.value.length > 0
  })

  function get_all_variants_out_of_stock (prod, flag_visibility = true){
    var available_variants = get_available_variants(prod, flag_visibility)
    return available_variants.filter((variant) => variant.stock !== null && variant.stock === 0).length === available_variants.length && available_variants.length > 0
  }

  function get_variant_has_stock (variant){
    return variant.stock > 0 || variant.stock === null
  }

	// expose managed state as return value
	return {
    get_store_discount,
    get_available_variants,
		available_variants,
		has_multiple_variants,
    get_has_multiple_variants,
		product_lowest_price_variant,
    get_product_lowest_price_variant,
		product_lowest_price,
    get_product_lowest_price,
    product_highest_price,
    get_product_highest_price,
		product_lowest_price_undiscounted,
    get_product_lowest_price_undiscounted,
		product_in_promo,
    get_product_in_promo,
    get_product_on_voucher,
		product_is_flexi_pack,
		product_is_cold_brew,
		product_is_equipment,
    product_is_pod,
		show_grind,
		coffee_plans,
		product_has_one_plan,
		productVariantHasPlan,
		getPlanFromVariant,
		has_variant_on_offer,
    get_has_variant_on_offer,
    get_has_variant_on_voucher,
    lowest_on_offer_variant,
    get_lowest_on_offer_variant,
    get_lowest_on_voucher_variant,
    getVariantFromVariantSku,
    getVariantDiscountText,
    has_label_artwork,
    card_bg_img,
    subscribeandsave_text,
    get_subscribeandsave_text,
    all_variants_out_of_stock,
    get_all_variants_out_of_stock,
    get_variant_has_stock,
		product_origin_flag,
		product_is_bag_tin,
    get_variant_in_promo,
    get_variant_on_voucher,
    variant_used_price
	}
}
