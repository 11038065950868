import { Action } from "../../internal/Action.js";
import { QualifierValue } from "../../internal/qualifier/QualifierValue.js";
import { Qualifier } from "../../internal/qualifier/Qualifier.js";
/**
 * @description A class that defines how to remove the background of an asset
 * @extends SDK.Action
 * @memberOf Actions.Effect
 * @see Visit {@link Actions.Effect|Effect} for an example
 */
class BackgroundRemoval extends Action {
    constructor() {
        super();
        this._actionModel.actionType = 'backgroundRemoval';
    }
    fineEdges(value) {
        this._fineEdges = value;
        if (this._fineEdges) {
            this._actionModel.fineEdges = this._fineEdges;
        }
        return this;
    }
    hints(value) {
        this._hints = value;
        if (this._hints) {
            this._actionModel.hints = this._hints;
        }
        return this;
    }
    prepareQualifiers() {
        var _a;
        let str = 'background_removal';
        if (this._fineEdges !== undefined) {
            str += `:${new QualifierValue(`fineedges_${this._fineEdges ? 'y' : 'n'}`).toString()}`;
        }
        if ((_a = this._hints) === null || _a === void 0 ? void 0 : _a.length) {
            str += `:${new QualifierValue(`hints_(${this._hints.join(';')})`).toString()}`;
        }
        this.addQualifier(new Qualifier('e', str));
    }
    static fromJson(actionModel) {
        const { fineEdges, hints } = actionModel;
        // We are using this() to allow inheriting classes to use super.fromJson.apply(this, [actionModel])
        // This allows the inheriting classes to determine the class to be created
        return new this().fineEdges(fineEdges).hints(hints);
    }
}
export { BackgroundRemoval };
